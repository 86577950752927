export function capitalizer(word) {
  if (word) {
    const updatedStatus = word === "at_origin" ? "at_pickup" : word;

    const mySentence = updatedStatus.replace(/_/g, " ");
    const words = mySentence.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  }
}
