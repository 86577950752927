import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "./loader/loader";
import RegisterRoute from "./router/register-route";
import PrivateRoute from "./router/private-route";
import VerifyRoute from "./router/verify-route";
import { ResetPasswordPage } from "./forgot-password/update-pass";
import CancelPolicy from "./cancel-policy";

const BasicLayout = lazy(() => import("./layout/basic-layout"));
const DefaultLayout = lazy(() => import("./layout/default-layout"));
const MapLayout = lazy(() => import("./layout/map-layout"));
const SignIn = lazy(() => import("./sign-in/sign-in"));
const SignUp = lazy(() => import("./sign-up/sign-up"));
const VerifyPhone = lazy(() => import("./verify-phone/verify-phone"));
const Home = lazy(() => import("./home/home"));
const Profile = lazy(() => import("./profile/profile"));
const Qoute = lazy(() => import("./quote/quote"));
const CreateOrder = lazy(() => import("./order/create-order"));
const ListOrder = lazy(() => import("./order/orders"));
const NotFound = lazy(() => import("./not-found/not-found"));
const ForgotPassword = lazy(() => import("./forgot-password"));
const SingleOrderPage = lazy(() => import("./single-order"));
const OrderTracking = lazy(() => import("./order-tracking"));

const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <RegisterRoute path="/sign-up">
          <BasicLayout>
            <Suspense fallback={<Loader />}>
              <SignUp />
            </Suspense>
          </BasicLayout>
        </RegisterRoute>
        <VerifyRoute path="/verify-phone">
          <BasicLayout>
            <Suspense fallback={<Loader />}>
              <VerifyPhone />
            </Suspense>
          </BasicLayout>
        </VerifyRoute>
        <PrivateRoute exact={true} path="/profile">
          <DefaultLayout>
            <Suspense fallback={<Loader />}>
              <Profile />
            </Suspense>
          </DefaultLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/create">
          <MapLayout>
            <Suspense fallback={<Loader />}>
              <Qoute />
            </Suspense>
          </MapLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/create-order">
          <DefaultLayout>
            <Suspense fallback={<Loader />}>
              <CreateOrder />
            </Suspense>
          </DefaultLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/orders">
          <DefaultLayout>
            <Suspense fallback={<Loader />}>
              <ListOrder />
            </Suspense>
          </DefaultLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/orders/:order_id">
          <DefaultLayout>
            <Suspense fallback={<Loader />}>
              <SingleOrderPage />
            </Suspense>
          </DefaultLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/orders/:order_id/tracking">
          <DefaultLayout>
            <Suspense fallback={<Loader />}>
              <OrderTracking />
            </Suspense>
          </DefaultLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/">
          <DefaultLayout>
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          </DefaultLayout>
        </PrivateRoute>
        <Route exact={true} path="/sign-in">
          <BasicLayout>
            <Suspense fallback={<Loader />}>
              <SignIn />
            </Suspense>
          </BasicLayout>
        </Route>
        <Route exact={true} path="/forgot-password">
          <BasicLayout>
            <Suspense fallback={<Loader />}>
              <ForgotPassword />
            </Suspense>
          </BasicLayout>
        </Route>
        <Route exact={true} path="/reset-password">
          <BasicLayout>
            <ResetPasswordPage />
          </BasicLayout>
        </Route>
        <Route exact={true} path="/cancel-policy">
          <DefaultLayout>
            <CancelPolicy />
          </DefaultLayout>
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
